import * as PIXI from "pixi.js";
import gsap from "gsap";
import { useUserStore } from "../store/userStore";
import {
    bucketBaseUrl,
    fadeInSprite,
    tweenShip,
    createShipTrail
} from "./latticeUtils";
import audioMixin from "../mixins/audioMixin.ts";
import type { shopShipsType } from "../interfaces/items";
import { useI18n } from "vue-i18n";

export default class ItemShop {
    shopShips: shopShipsType[];
    uStore: ReturnType<typeof useUserStore>;
    userShip: number;
    app: PIXI.Application | null = null;
    currentSelection: number;
    leftButton: PIXI.Sprite | undefined;
    rightButton: PIXI.Sprite | undefined;
    itemIds: number[] = [];
    ship: PIXI.Sprite | null = null;
    label: PIXI.Text | null = null;
    purchaseButton: PIXI.Graphics | null = null;
    purchaseLabel: PIXI.Text | null = null;
    buttonMode: string = "selected";
    gem: PIXI.Sprite | null = null;
    coins: PIXI.Text | null = null;
    i18n = useI18n();

    constructor() {
        this.shopShips = [];
        this.uStore = useUserStore();
        this.userShip = this.uStore.userPersonalization?.selected_ship?.id ?? 1;
        this.currentSelection = this.userShip;
        this.fetchItems();
    }

    async fetchItems() {
        try {
            const items =
                (await this.uStore?.userService?.fetchAllItems("ship")) || [];
            const userUnlockedShips =
                this.uStore.userPersonalization?.unlocked_items.map(
                    (item) => item.id
                ) || [];
            if (!items) {
                console.error("No items found");
                return;
            }
            items.forEach((item: any) => {
                const owned = userUnlockedShips.includes(item.id);
                this.shopShips[item.id] = { ...item, owned };
                this.itemIds.push(item.id);
            });
        } catch (err) {
            console.error("Error fetching user personalization:", err);
        }
    }

    async initPixi(container: HTMLElement) {
        if (container) {
            this.app = new PIXI.Application({
                width: container.offsetWidth,
                height: container.offsetHeight * 2,
                backgroundAlpha: 0,
                antialias: true
            });
            container.appendChild(this.app.view);
            this.app.stage.eventMode = "static";
            const containerPixi = new PIXI.Container();
            this.app.stage.addChild(containerPixi);
            this.app.stage.sortableChildren = true;
            this.createShopShips();
            this.createUserDetails();
            this.createSelectionButtons();
            this.createPurchaseButton();
            this._setupListeners();
        } else {
            console.warn("pixiContainer not yet available");
        }
    }

    async createShopShips() {
        if (!this.app) {
            console.log("App not yet available");
            return;
        }
        if (this.shopShips.length === 0) {
            await this.fetchItems();
            this._updateUserDetails();
        }
        let selectedShip = this.shopShips[this.currentSelection];
        let shipBlob = `${bucketBaseUrl}/${selectedShip?.blob_link}` || "";
        this.ship = new PIXI.Sprite(PIXI.Texture.EMPTY);

        const updateShipTexture = () => {
            if (!this.ship) {
                console.log("Ship not yet available");
                return;
            }
            this.ship.texture = PIXI.Texture.from(shipBlob);
        };

        updateShipTexture();
        this.ship.x = this.app.screen.width / 2;
        this.ship.y = this.app.screen.height / 2.25;

        this.ship.anchor.set(0.5);
        this.ship.zIndex = 2;
        this.ship.scale.set(0.3);

        fadeInSprite(this.ship);
        this.app.stage.addChild(this.ship);

        let labelText = selectedShip.owned
            ? this.i18n.t("shop.owned")
            : `$${selectedShip.value}`;
        this.label = new PIXI.Text(`${selectedShip.name}: ${labelText}`, {
            fill: "#ffffff"
        });
        this.label.x = this.app.screen.width / 2;
        this.label.y = this.app.screen.height / 1.5;
        this.label.anchor.set(0.5);
        this.label.zIndex = 3;

        fadeInSprite(this.label);
        this.app.stage.addChild(this.label);

        tweenShip(this.ship);
        createShipTrail(this.app, this.ship);
    }

    createUserDetails() {
        if (!this.app) {
            console.log("App not yet available");
            return;
        }
        const container = new PIXI.Container();
        container.x = this.app.screen.width * 0.5;
        container.y = this.app.screen.height * 0.9;
        container.pivot.x = container.width / 2;
        this.app.stage.addChild(container);

        const background = new PIXI.Graphics();
        background.beginFill(0x000000, 0.5);
        background.drawRoundedRect(0, 0, 300, 100, 20);
        background.endFill();
        container.addChild(background);
        this.gem = new PIXI.Sprite(PIXI.Texture.from("/images/gem.png"));
        this.gem.x = container.width / 3.5;
        this.gem.y = 45;
        this.gem.anchor.set(0.5);
        this.gem.scale.set(0.1);
        fadeInSprite(this.gem);
        container.addChild(this.gem);

        this.coins = new PIXI.Text(
            `${this.uStore.userPersonalization?.coins}`,
            {
                fill: "#ffffff",
                fontSize: 32,
                align: "center"
            }
        );
        this.coins.x = container.width / 2;
        this.coins.y = 45;
        this.coins.anchor.set(0.5);
        this.coins.zIndex = 3;
        fadeInSprite(this.coins);
        container.addChild(this.coins);
        container.pivot.x = container.width / 2;
    }

    _updateUserDetails() {
        if (this.coins) {
            let coins =
                parseInt(this.coins.text, 10) -
                this.shopShips[this.currentSelection].value;
            coins = Math.max(coins, 0);
            this.coins.text = coins.toString();
        } else {
            console.log("this.coins is undefined. Cannot update text.");
        }
    }

    createSelectionButtons() {
        if (!this.app) {
            console.log("App not yet available");
            return;
        }
        const btnLeft =
            "https://arkstatic-e7cra2b9fzcectfs.z02.azurefd.net/sensei-assets/websrc/General/UIElements/left_button.png";
        const btnRight =
            "https://arkstatic-e7cra2b9fzcectfs.z02.azurefd.net/sensei-assets/websrc/General/UIElements/right_button.png";

        this.leftButton = new PIXI.Sprite(PIXI.Texture.from(btnLeft));
        this.leftButton.x = this.app.screen.width * 0.1;
        this.leftButton.y = this.app.screen.height / 1.5;
        this.leftButton.anchor.set(0.5);
        this.leftButton.zIndex = 3;
        this.leftButton.eventMode = "static";
        this.leftButton.scale.set(1.25);
        fadeInSprite(this.leftButton);
        this.app.stage.addChild(this.leftButton);

        this.rightButton = new PIXI.Sprite(PIXI.Texture.from(btnRight));
        this.rightButton.x = this.app.screen.width * 0.9;
        this.rightButton.y = this.app.screen.height / 1.5;
        this.rightButton.anchor.set(0.5);
        this.rightButton.zIndex = 3;
        this.rightButton.eventMode = "static";
        this.rightButton.scale.set(1.25);
        fadeInSprite(this.rightButton);
        this.app.stage.addChild(this.rightButton);
    }

    createPurchaseButton() {
        if (!this.app) {
            console.log("App not yet available");
            return;
        }
        this.purchaseButton = new PIXI.Graphics();
        this.purchaseButton.beginFill(0xffffff, 0.75);
        this.purchaseButton.tint = 0x808080;
        this.purchaseButton.drawRoundedRect(0, 0, 200, 50, 13);
        this.purchaseButton.endFill();
        this.purchaseButton.x = this.app.screen.width / 4;
        this.purchaseButton.y = this.app.screen.height / 1.25;

        const style = new PIXI.TextStyle({
            fill: "white",
            fontSize: 24,
            fontWeight: "bold"
        });
        this.purchaseLabel = new PIXI.Text(this.i18n.t("shop.selected"), style);
        this.purchaseLabel.anchor.set(0.5);
        this.purchaseLabel.x = this.purchaseButton.width / 2;
        this.purchaseLabel.y = this.purchaseButton.height / 2;

        this.purchaseButton.zIndex = 3;
        this.purchaseButton.addChild(this.purchaseLabel);
        this.app.stage.addChild(this.purchaseButton);
    }

    _setupListeners() {
        if (
            !this.app ||
            !this.leftButton ||
            !this.rightButton ||
            !this.purchaseButton
        ) {
            console.log("App or buttons not yet available");
            return;
        }
        this.leftButton.on("pointerdown", this._onLeftButtonClick);
        this.rightButton.on("pointerdown", this._onRightButtonClick);
        this.purchaseButton.on("pointerdown", this._onPurchaseButtonClick);
    }

    _onLeftButtonClick = () => {
        const currentIndex = this.itemIds.indexOf(this.currentSelection);
        let nextIndex = currentIndex - 1;
        if (nextIndex < 0) {
            nextIndex = this.itemIds.length - 1;
        }
        this.currentSelection = this.itemIds[nextIndex];
        this._refreshDisplayedShip();
        audioMixin.methods?.playClickSound();
    };

    _onRightButtonClick = () => {
        const currentIndex = this.itemIds.indexOf(this.currentSelection);
        let nextIndex = currentIndex + 1;
        if (nextIndex >= this.itemIds.length) {
            nextIndex = 0;
        }
        this.currentSelection = this.itemIds[nextIndex];
        audioMixin.methods?.playClickSound();
        this._refreshDisplayedShip();
    };

    _onPurchaseButtonClick = () => {
        audioMixin.methods?.playClickSound();
        if (!this.app || !this.purchaseButton) {
            console.log("App or purchase button not yet available");
            return;
        }
        const splash = new PIXI.Graphics();
        splash.beginFill(0xffffff, 0.5);
        splash.drawRoundedRect(
            0,
            0,
            this.purchaseButton.width,
            this.purchaseButton.height,
            13
        );
        splash.endFill();
        splash.x = this.purchaseButton.x;
        splash.y = this.purchaseButton.y;
        splash.zIndex = 4;
        this.app.stage.addChild(splash);

        if (this.buttonMode === "select") {
            this._selectShip();
        }

        if (this.buttonMode === "purchase") {
            this._purchaseShip();
            this._updateUserDetails();
        }

        gsap.to(splash, {
            duration: 0.5,
            alpha: 0,
            onComplete: () => {
                this.app.stage.removeChild(splash);
            }
        });
    };

    _purchaseShip() {
        console.log("purchasing ship");
        if (!this._canPurchaseShip()) {
            console.log("Not enough coins to purchase ship");
            return;
        }
        try {
            this.uStore.purchaseItem(this.currentSelection);
        } catch (err) {
            console.error("Error purchasing ship:", err);
        } finally {
            this.fetchItems();
            this.userShip = this.currentSelection;
            this._refreshDisplayedShip();
        }
    }

    _selectShip() {
        console.log("selecting ship");
        try {
            this.uStore.updateUserPersonalization({
                selected_ship: this.currentSelection
            });
        } catch (err) {
            console.error("Error purchasing ship:", err);
        } finally {
            this.userShip = this.currentSelection;
            this._refreshDisplayedShip();
        }
    }

    _refreshDisplayedShip() {
        if (!this.app || !this.ship || !this.label) {
            console.log("App, ship, or label not yet available");
            return;
        }

        const selectedShip = this.shopShips[this.currentSelection];
        const shipBlob = `${bucketBaseUrl}/${selectedShip?.blob_link}` || "";
        this.ship.texture = PIXI.Texture.from(shipBlob);

        if (this.purchaseLabel && this.purchaseButton) {
            if (selectedShip.owned && this.currentSelection !== this.userShip) {
                this.label.text = `${selectedShip.name}: ${this.i18n.t("shop.owned")}`;
                this.purchaseLabel.text = this.i18n.t("shop.select");
                this.purchaseButton.tint = 0xffd534;
                this.purchaseButton.eventMode = "static";
                this.buttonMode = "select";
            } else if (this.currentSelection === this.userShip) {
                this.label.text = `${selectedShip.name}: ${this.i18n.t("shop.owned")}`;
                this.purchaseLabel.text = this.i18n.t("shop.selected");
                this.purchaseButton.tint = 0x808080;
                this.buttonMode = "selected";
            } else {
                this.label.text = `${selectedShip.name}: $${selectedShip.value}`;
                if (this._canPurchaseShip()) {
                    this.purchaseLabel.text = this.i18n.t("shop.purchase");
                    this.purchaseButton.tint = 0x2fdf75;
                    this.purchaseButton.eventMode = "static";
                    this.buttonMode = "purchase";
                } else {
                    this.purchaseLabel.text = this.i18n.t("shop.unavailable");
                    this.purchaseButton.tint = 0xff0000;
                    this.buttonMode = "unavailable";
                }
            }
        }
        this.ship.visible = true;

        gsap.fromTo(this.ship, { alpha: 0 }, { alpha: 1, duration: 0.5 });
        gsap.fromTo(this.label, { alpha: 0 }, { alpha: 1, duration: 0.5 });
    }

    _canPurchaseShip() {
        if (!this.uStore.userPersonalization) {
            console.log("User personalization not yet available");
            return false;
        }
        return (
            this.uStore.userPersonalization?.coins >=
            this.shopShips[this.currentSelection].value
        );
    }

    _removeListeners() {
        if (!this.app) {
            console.log("App or buttons not yet available");
            return;
        }

        this.leftButton?.off("pointerdown", this._onLeftButtonClick);
        this.rightButton?.off("pointerdown", this._onRightButtonClick);
        this.purchaseButton?.off("pointerdown", this._onPurchaseButtonClick);
    }

    _destroyPixi() {
        if (this.app) {
            gsap.killTweensOf("*");
            gsap.globalTimeline.clear();

            this.app.destroy(true, { children: true });
            this.app = null;
        }
    }

    destroy() {
        this._removeListeners();
        this._destroyPixi();
    }
}
